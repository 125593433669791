<template>
  <div class="wrapper">
        <div class="container splash ">
      <div class="row px-3">
        <div class="col-6 align-self-center">
        </div>
        <div class="col-6">
          <img style="width: 8.5rem;margin-right: -1.4rem;margin-top: 1.4rem;" class="float-right" src="assets/images/canpay-logo.png" />
        </div>
      </div>


      <div class="row px-3">
        <div class="col-12 text-center">
          <img class="upgrade-image" src="assets/images/upgrade-icon.png" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 upgrade-to-the-new-CanPay">
          <label>Sign Up for the new <br/>CanPay App </label>
        </div>
      </div>


      <div class="col-12 enter-the-email-you-use-to-sign-in-to-CanPay margin-ipad-pro"></div>

      <div class="row mt-5 px-3">
        <div class="col-12 mt-5">
          <button onclick="location.href='https://app.canpaydebit.com/splash'" type="button" class="btn-login btn-get-started mt-5">
                        Get Started
                    </button>
        </div>
      </div>
    </div>
    </div>
</template>
<style type="text/css">
.splash {
    background-color: white !important;
    max-width:1140px !important;
  }

.back-btn-onboarding-header {
  height: 2.7rem;
  width: 2.7rem;
}
.upgrade-image {
  height: 7rem !important;
  width: 7rem !important;
  margin: 2.5rem !important;
}

.upgrade-to-the-new-CanPay {
  font-size: 1.4rem !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
.enter-the-email-you-use-to-sign-in-to-CanPay {
  font-size: 0.8rem !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.btn-login {
  height: 50px !important;
  border-radius: 6px !important;
  width: 100% !important;
  border-color: transparent !important;
  background-color:#000000 !important;
  font-family: #118037;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top: 5px !important;
  cursor: pointer;
}
.btn-get-started {
  background-color: #149240 !important;
  margin-bottom: 2rem !important;
}
.input-box-row {
  margin-top: 10px !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.re-send-verification {
  font-size: 0.8rem;
  margin-top: -0.5rem !important;
}
.cursor-pointer {
  cursor: pointer;
}
.black
{
  color: #000000;
}
.white
{
  color:#ffffff;
}
@media only screen and (max-width: 1024px) and (min-height: 1366px) {
  .margin-ipad-pro {
    margin-left: 2.3rem !important;
  }
}
</style>
<script>
export default {
  name: "Blank",
  mounted() {
    this.$root.$emit("show_header", false);
    this.$root.$emit("changeWhiteBackground", [true, false, ""]);
    var app = document.getElementById("app");
    if (app) {
      app.style.setProperty("background-color", "#ffffff");
    }
    var element = document.getElementsByClassName("content-wrap");
    if (element[0]) {
      element[0].style.setProperty("background-color", "#ffffff");
    }
  },
  unmounted() {
    var e1 = document.getElementById("app");
    if (e1) {
      e1.style.setProperty("background-color", "#149240");
    }
  },
};
</script>
